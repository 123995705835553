
  import Vue , { defineComponent } from "vue";
  import CategoryApi from "@/apis/CategoryApi";;

  export default defineComponent({
    name: 'CategoryList',
    components: {
      //peiiNavbar,
    },
    props: {
    },
    data() {
      return {
        cookie: this.$cookies,
        currentLocation: new Array<string>(),
        levelChooses: new Map<string, string>(),
        classToNumberChooses: new Map<string, number>(),
        categoryIdToNuberOfQuizChoose: new Map<string, number>(),
        treeData: null as any,
        displayArray: [] as any[],
        beClickLevelList: [] as any[],
        pathList: [] as any[],
        quizNumberList : [] as any,
        endElement : false,
        hasChosenNumber : null ,
        chooseAllCategoryId: [] as any,
        chooseUuid:'',
        isLoading: false,
        canInput: false,
        newCategoryName: '',
        parentId: ''
      };
    },
    watch: {

    },
    computed: {

    },
    methods: {
      async addNewNode(){
        console.log("parent id = ", this.parentId)
        console.log("newCategoryName = ", this.newCategoryName)
        if(this.newCategoryName.includes("/")){
          alert("輸入的分類包含「/」，會被視為兩個分類，請重新輸入")
          this.newCategoryName = ''
        }
        else{
          let status = await CategoryApi.createNode(this.parentId, this.newCategoryName)
          alert("新增成功")
          this.$emit('handleModalClose')
        }
      },
      itemConfirm(){
        this.canInput = true
      },
      getbeClickLevelName(index: number){
        if( index >= this.beClickLevelList.length ){
          return "请选择"
        }
        else{
          return this.beClickLevelList[index].name
        }
      },
      elementClickedHandler(depth: number, index: number, uuid: string, isLeaf:boolean, name:string) {
        console.log(depth, index, uuid, isLeaf);
        let tempDisplayArray = this.displayArray;

        //你選完後 数量應該是 depth +1

        while(depth != this.beClickLevelList.length){
          this.beClickLevelList.pop();
        }

        // 檢查同一層,是不是已經有已選的element
        let hasSelected = false;
        this.displayArray[depth].forEach(
          (element: { isSelected: any }, forEachIndex: any) => {
            if (element.isSelected) {
              hasSelected = true;
              tempDisplayArray[depth][forEachIndex].isSelected = false;
            }
          }
        );

        if (depth < tempDisplayArray.length) {
          this.displayArray = this.displayArray.slice(0, depth + 1);
        }

        //前面選过 再選前面的 把它 mark 掉
        if (depth == 0 && this.displayArray.length > 1) {
          this.displayArray[1].array.forEach(
            (element: { isSelected: boolean }) => {
              element.isSelected = false;
            }
          );
        }

        if (!this.displayArray[depth][index].isLeaf ) {
          this.displayArray.push( this.displayArray[depth][index].child );
          this.displayArray[depth][index].isSelected = true;
          for( let i = 0 ; i < this.displayArray[depth+1].length ; i ++ ){
            this.displayArray[depth + 1][i].isSelected = false
          }
        }else{
          this.displayArray[depth][index].isSelected = true;
        }

        let pushItem = { uuid: "", name: "", isLeaf:false, abilityToNumberOfQuiz:null};
        pushItem.uuid = this.displayArray[depth][index].uuid;
        pushItem.name = this.displayArray[depth][index].name;
        pushItem.isLeaf = this.displayArray[depth][index].isLeaf;
        if(this.displayArray[depth][index].abilityToNumberOfQuiz) pushItem.abilityToNumberOfQuiz = this.displayArray[depth][index].abilityToNumberOfQuiz;
        this.beClickLevelList.push(pushItem);
      },
      addNewPathInList(){
        this.canInput = true
        if(this.beClickLevelList.length != 0 ){
          let path = "";
          console.log("this.beClickLevelList.length: " + this.beClickLevelList.length);
          for(let i = 0 ; i < this.beClickLevelList.length  ; i++){
            path = path + this.beClickLevelList[i].name

            if( i < this.beClickLevelList.length - 1){
              path = path + "/";
            }
            else{//確認new path 和目前pathList中有沒有父子關係
              let isParentChild = false;
              if(!this.checkDuplicatePath(path)){
                for(let j = 0; j < this.pathList.length; j++){
                  console.log("this.pathList[j].path: " + this.pathList[j].path);
                  console.log("new path" + path);
                  if(path.includes(this.pathList[j].path)){ //pathlist 是 path(new path)的parent
                    isParentChild = true;
                  }
                  else if(this.pathList[j].path.includes(path)){
                      console.log("this.pathList[j].path.includes(path)");
                      this.pathList[j].path = path;
                      this.pathList[j].uuid = this.beClickLevelList[i].uuid;
                  }
                }
              }
              for(let k = 0; k < this.pathList.length; k++){//確認目前pathList中有沒有重複的
                for(let l = k+1; l < this.pathList.length; l++){
                  if(this.pathList[k].path == this.pathList[l].path){
                    this.removePathFromPathList(this.pathList[k].path);
                  }
                }
              }
              if(!this.checkDuplicatePath(path) && !isParentChild){//選擇的類別和以選擇的沒有重複也沒有父子關係
                let pathItem = { uuid: "", path: "", numberForPathItem: null, totalVocabularyListening: 0, totalLiteral: 0};
                // pathItem.path = path + '/' + this.hasChosenNumber + '题';
                // if(this.beClickLevelList[i].isLeaf) pathItem.path = path + '/';
                pathItem.path = path;
                pathItem.uuid = this.beClickLevelList[i].uuid;
                pathItem.numberForPathItem =  this.beClickLevelList[i].abilityToNumberOfQuiz
                this.chooseAllCategoryId.push(this.beClickLevelList[i].uuid);
                this.$emit('getCategoryId', this.beClickLevelList[i].uuid)
                this.pathList.push( pathItem );
                this.parentId = pathItem.uuid
              }
            }
          }
        }
        else{
          alert("尚未選擇分類")
        }
        // this.quizNumberList.push(this.hasChosenNumber);
        this.refreshBeclicked();
      },
      removePathFromPathList(path : string){
        let paths = [] as any;
        this.pathList.forEach(( pathItem: any) =>{
            if(pathItem.path != path  ){
              paths.push(pathItem)
            }
          });
        this.pathList = paths
      },
      checkDuplicatePath(path : string){
        if(this.pathList.length == 0){
          return false;
        }
        for(let i = 0 ; i < this.pathList.length ; i++){
          if(this.pathList[i].path == path ){
            return true
          }
        }
        return false;
      },
      refreshBeclicked(){
        let level0 = [] as any;
        this.endElement = false;
        this.hasChosenNumber = null;
        this.treeData.child.forEach((element:any) => {
        // 設定第0層的每一個元素
          let elementObject = { uuid: "", name: "" , child: [], isSelected: false, depth: 0, isLeaf: false, abilityToNumberOfQuiz: null};
          elementObject.uuid = element.uuid;
          elementObject.name = element.name;
          elementObject.child = element.child
          elementObject.isLeaf = element.isLeaf;
          if(element.abilityToNumberOfQuiz) {
            elementObject.abilityToNumberOfQuiz = element.abilityToNumberOfQuiz;
          }
          level0.push(elementObject);
        });
        this.displayArray = [level0];
        this.beClickLevelList = [] as any;
      },
      async getCategoryList(){
        this.$store.commit('updateLoading', true);
        try{
          let remoteCategory;
          let uuid='';
          let jsonString = '';
          if(this.$router.currentRoute.path.includes('exam-category')) jsonString = await CategoryApi.getRoot("quiz");
          else/*lecture-category*/ jsonString = await CategoryApi.getRoot("course");
          uuid = JSON.parse(JSON.stringify(jsonString)).uuid;
          remoteCategory = await CategoryApi.getNodeById(uuid)
          this.treeData = JSON.parse(JSON.stringify(remoteCategory));
          let level0 = [] as any;
          this.treeData.child.forEach((element: any) => {
          // 設定第0層的每一個元素
            let elementObject = { uuid: "", name: "" , child: [], isSelected: false, depth: 0, isLeaf: false, abilityToNumberOfQuiz: null};
            elementObject.uuid = element.uuid;
            elementObject.name = element.name;
            elementObject.child = element.child;
            elementObject.isLeaf = element.isLeaf;
            if(element.abilityToNumberOfQuiz) {
              elementObject.abilityToNumberOfQuiz = element.abilityToNumberOfQuiz;
            }
            level0.push(elementObject);
          });
          this.displayArray = [level0];
        }
        catch (error) {
          console.log(error);
        }
        finally{
          this.$store.commit('updateLoading', false);
        }
      },
    },
    created() {
      this.getCategoryList();
    },
});
  