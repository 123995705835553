import api from '@/apis/BaseApi';
import vm from '@/main';
import { sellPlanModel} from "@/models/WorkspaceModel";

export default class WorkspaceSellPlanApi {
    static async getSellPlanByName(name:string, pageNumber:number): Promise<string> {
        const result = await api.get(`/workspace/sell-plans/?name=${name}&pageNumber=${pageNumber}&pageSize=20`,);
        return result.data;
    }

    static async getSellPlanById(sellPlanId:string): Promise<string> {
        const result = await api.get(`/workspace/sell-plans/${sellPlanId}`,);
        return result.data;
    }
    static async deleteSellPlan(sellPlanId:string): Promise<string> {
        const result = await api.delete(`/workspace/sell-plans/${sellPlanId}`,);
        return result.data;
    }
    static async updateSellPlan(sellPlanModel: sellPlanModel): Promise<string> {
        let params: { [key: string]: any } = {};

        // 檢查每個欄位，若不為空則加入到參數物件中
        if (sellPlanModel.metadata.name!="") {
            params.name = sellPlanModel.metadata.name;
        }
        if (sellPlanModel.description!="") {
            params.description = sellPlanModel.description;
        }
        if (sellPlanModel.showImageUrl!="") {
            params.showImageUrl = sellPlanModel.showImageUrl;
        }
        if (sellPlanModel.categoryIds.length > 0) {
            params.categoryNodeIds = sellPlanModel.categoryIds;
        }

        params.numberLimit = sellPlanModel.numberLimit
        params.isDisplay = sellPlanModel.isDisplay
        params.timeLimit = sellPlanModel.timeLimit
        params.price = sellPlanModel.price
        params.isRandom = sellPlanModel.isRandom
        params.isBigCourse = sellPlanModel.isBigCourse
        params.isUserChoose = sellPlanModel.isUserChoose
        params.type = sellPlanModel.sellType.toLowerCase()
        // const result = await api.post(`/workspace/sell-plans/${sellPlanModel.uuid}`,
        //     {   name: sellPlanModel.metadata.name,
        //         description: sellPlanModel.description,
        //         showImageUrl: sellPlanModel.showImageUrl,
        //         numberLimit: sellPlanModel.numberLimit,
        //         timeLimit:sellPlanModel.timeLimit,
        //         price: sellPlanModel.price,
        //         isRandom: sellPlanModel.isRandom,
        //         isBigCourse: sellPlanModel.isBigCourse,
        //         isUserChoose: sellPlanModel.isUserChoose,
        //         categoryNodeIds: sellPlanModel.categoryIds,
        //         type: sellPlanModel.sellType.toLowerCase()
        //     });
        const result = await api.post(`/workspace/sell-plans/${sellPlanModel.uuid}`,params);
        return result.data;
    }
    static async createSellPlan(name:string, parentId:string): Promise<string> {
        const result = await api.post(`/workspace/sell-plans`, {name: name, parentId: parentId});
        return result.data;
    }
    // static async createSellPlan(courseId:string, name:string, parantId:string): Promise<string> {
    //     const result = await api.post(`/workspace/courses`, {name: name, parantId: parantId});
    //     return result.data;
    // }
}