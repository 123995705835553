import api from '@/apis/BaseApi';
export default class CategoryApi {
    static async getNodeById(categoryNodeId: string): Promise<string> {
        const result = await api.get(`/category/${categoryNodeId}`);
        return result.data;
    }
    static async getRoot(type: string): Promise<string> {
        const result = await api.get(`/category/root/?root=${type}`);
        return result.data;
    }
    static async getCategoryHierarchy(type: string): Promise<string> {
        const result = await api.get(`/category/getHierarchy/?source=${type}`);
        return result.data;
    }
    static async createNode(parentId: string, name: string): Promise<string> {
        const result = await api.post(`/category/`, {parentId:parentId, name:name});
        return result.data;
    }
    static async getCategoryByName(name:string, pageNumber:number, type: string): Promise<string> {
        const result = await api.get(`/category/?name=${name}&pageNumber=${pageNumber}&pageSize=20&source=${type}`,);
        return result.data;
    }
    static async deleteCategory(categoryId:string): Promise<string> {
        const result = await api.delete(`/category/${categoryId}`,);
        return result.data;
    }
    static async updateCategoryName(categoryId: string, formData:FormData): Promise<string> {
        const result = await api.put(`/category/${categoryId}`, formData);
        return result.data;
    }
}